import React from 'react'
import './SecondaryButton.scss'

const SecondaryButton = ({ children, onClick, fontSize, disabled, style, buttonSize }) => {
  return (
    <button
      className={`custom-button-new ${disabled ? 'disabled-secondary-button' : ''} ${buttonSize === "sm" ? "small-secondary-button" : buttonSize === "md" ? "medium-secondary-button": "large-secondary-button"}`}
      disabled={disabled}
      onClick={!disabled ? onClick : undefined}
      style={{ fontSize, ...style }}
    >
      {children}
    </button>
  )
}

export default SecondaryButton