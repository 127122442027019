export const supplierEndPoint = (build) => ({
  // Supplier Creation/Management
  getExistingSuppliers: build.query({
    query: (arg) => ({
      url: "supplier/suppliers",
      params: { ...arg }
    }),
    providesTags: ["Suppliers"]
  }),

  createSupplier: build.mutation({
    query: (body) => ({
      url: "supplier/create",
      method: "POST",
      body: body
    }),
    invalidatesTags: ["Suppliers"]
  }),

  getAllSuppliers: build.query({
    query: (arg) => ({
      url: "supplier/list",
      params: { ...arg }
    }),
    providesTags: ["Suppliers"]
  }),

  downloadTemplate: build.query({
    query: () => ({
      url: "supplier/download-template"
    })
  }),

  getSupplierDetail: build.query({
    query: (id) => ({
      url: `supplier/${id}`
    }),
    providesTags: ["Suppliers"]
  }),

  updateSupplierDetails: build.mutation({
    query: (body) => ({
      url: "supplier/update",
      method: "PUT",
      body: body
    }),
    invalidatesTags: ["Suppliers"]
  }),

  uploadSupplierIdProof: build.mutation({
    query: (formData) => ({
      url: "supplier/upload-id",
      method: "POST",
      body: formData
    }),
    invalidatesTags: ["Suppliers"]
  }),

  removeSupplier: build.mutation({
    query: (body) => ({
      url: "supplier/delete",
      method: "POST",
      body: body
    }),
    invalidatesTags: ["Suppliers"]
  }),

  // Supplier Contacts
  createContact: build.mutation({
    query: (body) => ({
      url: "supplier/contact/create",
      method: "POST",
      body: body
    }),
    invalidatesTags: ["SupplierContacts"]
  }),

  updateContact: build.mutation({
    query: (body) => ({
      url: "supplier/contact/update",
      method: "PUT",
      body: body
    }),
    invalidatesTags: ["SupplierContacts"]
  }),

  getAllContacts: build.query({
    query: (arg) => ({
      url: "supplier/contact/list",
      params: { ...arg }
    }),
    providesTags: ["SupplierContacts"]
  }),

  getContactDetail: build.query({
    query: (id) => ({
      url: `supplier/contact/${id}`
    }),
    providesTags: ["SupplierContacts"]
  }),

  deleteContact: build.mutation({
    query: (body) => ({
      url: "supplier/contact/delete",
      method: "POST",
      body: body
    }),
    invalidatesTags: ["SupplierContacts"]
  }),

  // Supplier Groups
  createGroup: build.mutation({
    query: (body) => ({
      url: "supplier/group/create",
      method: "POST",
      body: body
    }),
    invalidatesTags: ["SupplierGroups"]
  }),

  updateGroup: build.mutation({
    query: (body) => ({
      url: "supplier/group/update",
      method: "PUT",
      body: body
    }),
    invalidatesTags: ["SupplierGroups"]
  }),

  getListAllGroups: build.query({
    query: (arg) => ({
      url: "supplier/group/list",
      params: { ...arg }
    }),
    providesTags: ["SupplierGroups"]
  }),

  deleteGroup: build.mutation({
    query: (body) => ({
      url: "supplier/group/delete",
      method: "POST",
      body: body
    }),
    invalidatesTags: ["SupplierGroups"]
  }),

  assignSupplier: build.mutation({
    query: (body) => ({
      url: "supplier/group/assign-supplier",
      method: "PUT",
      body: body
    }),
    invalidatesTags: ["SupplierGroups", "Suppliers"]
  }),

  unassignSupplier: build.mutation({
    query: (body) => ({
      url: "supplier/group/unassign-supplier",
      method: "PUT",
      body: body
    }),
    invalidatesTags: ["SupplierGroups", "Suppliers"]
  }),

  mergeGroups: build.mutation({
    query: (body) => ({
      url: "supplier/group/merge",
      method: "POST",
      body: body
    }),
    invalidatesTags: ["SupplierGroups"]
  }),

  getGroupSuppliers: build.query({
    query: (arg) => ({
      url: "supplier/group/suppliers",
      params: { ...arg }
    }),
    providesTags: ["SupplierGroups", "Suppliers"]
  }),

  getGroupDetail: build.query({
    query: (id) => ({
      url: `supplier/group/${id}`
    }),
    providesTags: ["SupplierGroups"]
  }),

  getDownloadTemplateFields: build.query({
    query: () => ({
      url: "supplier/download-template-fields"
    }),
    providesTags: ["SupplierBulkUpload"]
  }),

  uploadTemplate: build.mutation({
    query: (formData) => ({
      url: "supplier/upload",
      method: "POST",
      body: formData
    }),
    invalidatesTags: ["SupplierPastUpload"]
  }),

  getSupplierPastUploads: build.query({
    query: () => ({
      url: "supplier/uploads",
    }),
    providesTags: ["SupplierPastUpload"]
  }),

  getGroupUnlinkedSuppliers: build.query({
    query: (arg) => ({
      url: "supplier/group/unlinked-suppliers",
      params: { ...arg }
    }),
    providesTags: ["UnlinkedSuppliers"]
  }),
});
