export default function findChangedFields(prevState, currentState) {
  const changedFields = {};

  Object.keys(currentState).forEach((key) => {
    if (prevState[key] !== currentState[key]) {
      changedFields[key] = {
        prevValue: prevState[key],
        currentValue: currentState[key]
      };
    }
  });

  return changedFields;
}

// function stringToColor(string) {
//   let hash = 0;
//   let i;

//   /* eslint-disable no-bitwise */
//   for (i = 0; i < string.length; i += 1) {
//     hash = string.charCodeAt(i) + ((hash << 5) - hash);
//   }

//   let color = "#";

//   for (i = 0; i < 3; i += 1) {
//     const value = (hash >> (i * 8)) & 0xff;
//     color += `00${value.toString(16)}`.slice(-2);
//   }
//   /* eslint-enable no-bitwise */

//   return color;
// }

export function stringAvatar(name) {
  return {
    children: `${name?.split(" ")[0][0]}${name?.split(" ")[1][0]}`
  };
}

export function capitalizeFirstLetter(str) {
  if (typeof str !== "string" || str.length === 0) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const removeOnlyFacilityIdObjects = (arr) => {
  return arr.filter((obj) => !(Object.keys(obj).length === 1 && obj.hasOwnProperty("facilityId")));
};

export function isNestedObjectEmpty(obj) {
  if (typeof obj !== "object" || obj === null) return false;

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      if (typeof value === "object" && !isNestedObjectEmpty(value)) {
        return false;
      }
      if (value !== null && value !== undefined && value !== "") {
        return false;
      }
    }
  }
  return true;
}

export function validateEmail(email) {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(String(email).toLowerCase());
}

// export const getRandomColor = (colors) => colors[Math.floor(Math.random() * colors.length)];
export const getRandomColor = (colors, ind) => colors[ind % colors.length];

export const toSentenceCase = (text) => {
  if (!text) return "";

  if (text === "NA") return "NA";

  return text
    .split(" ")
    .map((word) => {
      if (word.includes("-")) {
        return word
          .split("-")
          .map((part) => {
            return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
          })
          .join("-");
      }

      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
};

export const getDisplayValue = (value, isFieldName = false) => {
  if (!value) return "";

  if (isFieldName) {
    value = value.replace(/([A-Z])/g, " $1").trim();
  }

  return toSentenceCase(value);
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const formattedDate = date
    .toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    })
    .replace(/\//g, ".");

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${formattedDate} ${hours}:${minutes}:${seconds}`;
};

export const formatFileSize = (size) => {
  if (size < 1) return `${(size * 1024)?.toFixed(0)} KB`;
  return `${size?.toFixed(2)} MB`;
};

export const formatStorageSize = (sizeInMB) => {
  if (sizeInMB < 1024) {
    return `${sizeInMB.toFixed(2)} MB`;
  } else {
    return `${(sizeInMB / 1024).toFixed(2)} GB`;
  }
};

export const timeAgo = (date) => {
  const now = new Date();
  const commentDate = new Date(date);
  const seconds = Math.floor((now - commentDate) / 1000);

  let interval = Math.floor(seconds / 31536000);
  if (interval >= 1) return `${interval} year${interval > 1 ? "s" : ""} ago`;
  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) return `${interval} month${interval > 1 ? "s" : ""} ago`;
  interval = Math.floor(seconds / 86400);
  if (interval >= 1) return `${interval} day${interval > 1 ? "s" : ""} ago`;
  interval = Math.floor(seconds / 3600);
  if (interval >= 1) return `${interval} hour${interval > 1 ? "s" : ""} ago`;
  interval = Math.floor(seconds / 60);
  if (interval >= 1) return `${interval} minute${interval > 1 ? "s" : ""} ago`;
  return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
};

export const formatBytes = (bytes) => {
  if (typeof bytes !== "number" || isNaN(bytes)) {
    throw new Error("Input must be a valid number");
  }

  const units = ["B", "KB", "MB", "GB", "TB"];
  let unitIndex = 0;

  while (bytes >= 1024 && unitIndex < units.length - 1) {
    bytes /= 1024;
    unitIndex++;
  }

  return `${Math.round(bytes)}${units[unitIndex]}`;
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};
