import { createSlice } from "@reduxjs/toolkit";

const initialSupplierState = {
  supplier: {}
};

export const supplierSlice = createSlice({
  name: "supplier",
  initialState: initialSupplierState,
  reducers: {
    setSupplierDetail: (state, action) => {
      return {
        ...state,
        supplier: action.payload
      };
    }
  }
});

export const { setSupplierDetail } = supplierSlice.actions;

export default supplierSlice.reducer;
