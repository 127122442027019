import dayjs from "dayjs";

function deepMergeObject(target, source) {
  //this function is used to deep merge two objects
  for (const key in source) {
    if (typeof source[key] === "object" && source[key] !== null) {
      if (!target[key]) {
        target[key] = Array.isArray(source[key]) ? [] : {};
      }
      deepMergeObject(target[key], source[key]);
    } else {
      target[key] = source[key];
    }
  }
  return target;
}

function deepCopyWithUndefined(obj) {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  const copiedObj = Array.isArray(obj) ? [] : {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      copiedObj[key] = deepCopyWithUndefined(obj[key]);
    }
  }

  return copiedObj;
}

function allValuesTrue(obj) {
  for (let key in obj) {
    if (!obj[key]) {
      return false;
    }
  }
  return true;
}

function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}

function updateOrCreate(arr, id, newValues) {
  const index = arr.findIndex((item) => item.facilityId === id);

  if (index !== -1) {
    arr[index] = { ...arr[index], ...newValues };
  } else {
    arr.push({ facilityId: id, ...newValues });
  }

  return arr;
}

function updateOrReplace(arr, id, newValues) {
  const index = arr.findIndex((item) => item.facilityId === id);

  if (index !== -1) {
    arr[index] = { ...arr[index], ...newValues };
  } else {
    arr = [{ facilityId: id, ...newValues }];
  }

  return arr;
}

function updateOrCreateBulk(qId, arr, id, newValues) {
  let obj = {};
  const index = arr.findIndex((item) => item.facilityId === id);

  if (index !== -1) {
    arr[index] = { ...arr[index], ...newValues };
  } else {
    arr.push({ facilityId: id, ...newValues });
  }
  obj[qId] = arr;

  return obj;
}

function removeIdKey(qId, obj, newValues, facId) {
  const updatedObj = { ...obj };

  for (const questionId in updatedObj) {
    if (questionId === qId) {
      updatedObj[questionId] = updatedObj[questionId].map((entry) => {
        if (entry.facilityId !== facId) {
          if (newValues === "makerId") {
            const { makerId, ...rest } = entry;
            return rest;
          } else {
            const { reviewerApproverId, ...rest } = entry;
            return rest;
          }
        }
        return entry;
      });
    }
  }

  return updatedObj;
}

function updateOrReplaceBulk(qId, arr, id, newValues) {
  let obj = {};
  const index = arr.findIndex((item) => item.facilityId === id);

  if (index !== -1) {
    arr[index] = { ...arr[index], ...newValues };
  } else {
    arr = [{ facilityId: id, ...newValues }];
  }
  obj[qId] = arr;

  return obj;
}

function getInitials(name) {
  const [firstName, lastName] = name.split(" ");
  return firstName.charAt(0) + lastName.charAt(0);
}

function getThreeObjectsById(id, data) {
  const index = data.findIndex((question) => Object.keys(question)[0] === id);
  if (index === -1) return { objects: [], groupIndex: -1 };

  const groupIndex = Math.floor(index / 3);

  const startIndex = groupIndex * 3;

  const objects = data.slice(startIndex, startIndex + 3);
  return { objects, groupIndex: startIndex };
}

function isEmptyObject(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

function formatQNos(arr) {
  const qNos = arr.map((item) => item.qNo);
  if (qNos.length === 1) return qNos[0];
  const lastQNo = qNos.pop();
  return `${qNos.join(", ")} and ${lastQNo}`;
}

function transformArrayOfArraysToNestedStructure(arrOfArrays) {
  const rootMap = new Map(); // Root-level map to track first-level parents

  arrOfArrays?.forEach((path) => {
    let currentLevel = rootMap; // Start from root map

    path.forEach((item, index) => {
      if (!currentLevel?.has(item)) {
        currentLevel?.set(item, {
          value: item,
          label: item,
          children: index === path.length - 1 ? undefined : new Map() // Only add children map if it's not the last item
        });
      }

      // Move to the next level in the hierarchy
      currentLevel = currentLevel?.get(item).children;
    });
  });

  // Convert the Map structure into a nested array
  function convertMapToArray(map) {
    return Array.from(map.values()).map((node) => ({
      value: node.value,
      label: node.label,
      children: node.children ? convertMapToArray(node.children) : undefined
    }));
  }

  return convertMapToArray(rootMap);
}

function getCurrentFinancialYear() {
  const currentDate = dayjs();
  const currentYear = currentDate.year();
  const startOfFY = dayjs(`${currentYear}-04-01`);

  return currentDate.isBefore(startOfFY) ? `${currentYear - 1}-${currentYear}` : `${currentYear}-${currentYear + 1}`;
}

export {
  deepMergeObject,
  deepCopyWithUndefined,
  allValuesTrue,
  isValidEmail,
  updateOrCreate,
  updateOrCreateBulk,
  getInitials,
  getThreeObjectsById,
  isEmptyObject,
  updateOrReplace,
  updateOrReplaceBulk,
  formatQNos,
  removeIdKey,
  transformArrayOfArraysToNestedStructure,
  getCurrentFinancialYear
};
