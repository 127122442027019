export const emissionEndpoints = (build) => ({
  getTemplateList: build.query({
    query: (arg) => ({
      url: `emission/templete/list`,
      params: { ...arg }
    }),
    providesTags: ["Cards"]
  }),

  getDataEntries: build.query({
    query: (arg) => ({
      url: `emission/data`,
      params: { ...arg }
    }),
    providesTags: ["DataEntries"]
  }),
  getMonthWiseData: build.query({
    query: (year) => `emission/data/month?year=${year}`
  }),

  getScopeWiseData: build.query({
    query: (year) => `emission/data/scope?year=${year}`
  }),

  getFacilityWiseData: build.query({
    query: (year) => `emission/data/month/facility?year=${year}`
  }),
  getCategoryTableData: build.query({
    query: (id) => `emission/templete/detail?id=${id}`,
    providesTags: ["DataTableEntries"]
  }),
  postCalculateEmissionRow: build.mutation({
    query: (postBody) => ({
      url: `emission/calculate`,
      method: "POST",
      body: postBody
    }),

    transformResponse: (response) => {
      return response;
    },

    invalidatesTags: ["Facility"]
  }),
  postEmissionTableData: build.mutation({
    query: (postBody) => ({
      url: `emission/data`,
      method: "POST",
      body: postBody
    }),

    transformResponse: (response) => {
      return response;
    },

    invalidatesTags: [
      "Dashscope",
      "Dashmonthscope",
      "Dashmonthfacility",
      "Topfacility",
      "Topcategory",
      "Topsubcategory",
      "Scopecategory",
      "AnalysisFilter",
      "scopewiseEm",
      "analysisrenewable",
      "analysisscope",
      "analysistrendyear",
      "analysistrendsource",
      "analysisfacility",
      "analysismonth",
      "analysissubcategory",
      "analysiscategory",
      "analysisyeartrend",
      "AnomalyReviewData",
      "AnomalyGraphReview"
    ]
  }),
  updateEmissionEntry: build.mutation({
    query: (postBody) => ({
      url: "emission/data",
      method: "PUT",
      body: postBody
    }),

    invalidatesTags: [
      "DataEntries",
      "Dashscope",
      "Dashmonthscope",
      "Dashmonthfacility",
      "Topfacility",
      "Topcategory",
      "Topsubcategory",
      "Scopecategory",
      "AnalysisFilter",
      "scopewiseEm",
      "analysisrenewable",
      "analysisscope",
      "analysistrendyear",
      "analysistrendsource",
      "analysisfacility",
      "analysismonth",
      "analysissubcategory",
      "analysiscategory",
      "analysisyeartrend",
      "AnomalyReviewData",
      "AnomalyGraphReview"
    ],

    transformResponse: (response) => {
      return response;
    }
  }),

  updateBill: build.mutation({
    query: (formData) => ({
      url: "emission/update/bill",
      method: "PUT",
      body: formData
    }),
    transformResponse: (response) => {
      return response;
    },
    invalidatesTags: ["DataEntries", "Files"]
  }),

  uploadBill: build.mutation({
    query: (formData) => ({
      url: "emission/upload/bill",
      method: "POST",
      body: formData
    }),
    transformResponse: (response) => {
      return response;
    },
    invalidatesTags: ["DataEntries", "Files"]
  }),

  getAnomalyExist: build.query({
    query: (arg) => ({
      url: "emission/anomalyExist",
      params: { ...arg }
    }),
    providesTags: ["AnomalyGraphReview"]
  }),

  getAnomalyReviewData: build.query({
    query: (arg) => ({
      url: "emission/review-data",
      params: { ...arg }
    }),
    providesTags: ["AnomalyReviewData"]
  }),

  resolveAnomaly: build.mutation({
    query: (arg) => ({
      url: "emission/resolve-anomaly",
      method: "PUT",
      body: arg
    }),

    transformResponse: (response) => {
      return response;
    },

    invalidatesTags: [
      "DataEntries",
      "AnomalyReviewData",
      "AnomalyGraphReview",
      "AnomalyGraph",
      "AnomalyConfigurations",
      "AnomalyHistoryExists",
      "AnomalyHistory"
    ]
  }),

  //----------------New Emission APIs---------------
  getMainCards: build.query({
    query: (arg) => ({
      url: "/category",
      params: { ...arg }
    }),
    providesTags: ["Maincards"]
  }),

  getSubCards: build.query({
    query: (arg) => ({
      url: `/sub-category/category/${arg.id}`,
      params: { ...arg.queryParams }
    }),
    providesTags: ["Subcards"]
  }),

  getSubCategoryDetails: build.query({
    query: (arg) => ({
      url: `/sub-category/${arg.id}`,
      params: { ...arg.queryParams }
    }),
    providesTags: ["Subcat"]
  }),

  getSubCategoryData: build.query({
    query: (arg) => ({
      url: `/emission/list`,
      params: arg
    }),
    providesTags: ["Subcardsdata"]
  }),
  getPastUploads: build.query({
    query: (arg) => ({
      url: `/emission/past-uploads`,
      params: arg
    }),
    providesTags: ["Pastupload"]
  }),
  getCalculationResult: build.query({
    query: (arg) => {
      // Create a properly serialized query string
      const searchParams = new URLSearchParams();
      for (const key in arg) {
        const value = arg[key];
        if (Array.isArray(value)) {
          // Add each array value as a separate parameter with the same key
          value.forEach((item) => {
            searchParams.append(key, item);
          });
        } else {
          // Serialize other values
          searchParams.append(key, value);
        }
      }

      // Return the properly formatted query configuration
      return {
        url: `/emission/calculateEmission?${searchParams.toString()}`
      };
    },
    providesTags: ["Calculation"]
  }),
  postEmissionData: build.mutation({
    query: (postBody) => ({
      url: `/emission/logEmission`,
      method: "POST",
      body: postBody
    }),

    transformResponse: (response) => {
      return response;
    },

    invalidatesTags: ["Subcardsdata", "Breakdown"]
  }),
  deleteEmissionData: build.mutation({
    query: (postBody) => ({
      url: `emission/delete`,
      method: "PUT",
      body: postBody
    }),

    transformResponse: (response) => {
      return response;
    },

    invalidatesTags: ["Subcardsdata", "Breakdown"]
  })
});
