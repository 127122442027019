export const analysisEndpoints = (build) => ({
  getAnalysisFilter: build.query({
    query: ({ filters, scope }) => {
      const params = new URLSearchParams();

      filters.forEach((filter) => params.append("filters", filter));

      if (scope) {
        params.append("scope", scope);
      }

      return {
        url: `analysis/filters`,
        params: params.toString()
      };
    },
    providesTags: ["AnalysisFilter"]
  }),

  getAnalysisSubFilter: build.query({
    query: ({ filters, scope }) => {
      const params = new URLSearchParams();

      filters.forEach((filter) => params.append("filters", filter));

      if (scope) {
        params.append("scope", scope);
      }

      return {
        url: `analysis/subfilters`,
        params: params.toString()
      };
    },
    providesTags: ["AnalysisSubFilter"]
  }),

  getEmissionBreakdown: build.query({
    query: (arg) => ({
      url: `/analysis/v2/breakdown`,
      params: arg
    }),
    providesTags: ["Breakdown"]
  }),
  getRetrieveScopWiseEmissionData: build.query({
    query: (arg) => {
      const params = new URLSearchParams();

      const appendNestedParams = (key, value) => {
        if (value === undefined) {
          return;
        }
        if (typeof value === "object" && !Array.isArray(value)) {
          Object.keys(value).forEach((subKey) => {
            if (value[subKey] !== undefined) {
              params.append(`${key}.${subKey}`, value[subKey]);
            }
          });
        } else if (Array.isArray(value)) {
          value.forEach((val) => {
            if (val !== undefined) {
              params.append(key, val);
            }
          });
        } else {
          params.append(key, value);
        }
      };

      // Iterate over the keys in the arg object and append them to params
      Object.keys(arg).forEach((key) => {
        appendNestedParams(key, arg[key]);
      });

      return {
        url: `analysis/scope`,
        params: params.toString()
      };
    },
    providesTags: ["scopewiseEm"]
  }),
  analysisTrendByYear: build.query({
    query: (arg) => {
      const params = new URLSearchParams();

      // Helper function to append nested objects
      const appendNestedParams = (key, value) => {
        if (value === undefined) {
          // Skip undefined values
          return;
        }
        if (typeof value === "object" && !Array.isArray(value)) {
          Object.keys(value).forEach((subKey) => {
            if (value[subKey] !== undefined) {
              params.append(`${key}.${subKey}`, value[subKey]);
            }
          });
        } else if (Array.isArray(value)) {
          value.forEach((val) => {
            if (val !== undefined) {
              params.append(key, val);
            }
          });
        } else {
          params.append(key, value);
        }
      };

      // Iterate over the keys in the arg object and append them to params
      Object.keys(arg).forEach((key) => {
        appendNestedParams(key, arg[key]);
      });

      return {
        url: `analysis/trend`,
        params: params.toString()
      };
    },
    providesTags: ["analysisyeartrend"]
  }),

  postAnalysisCategoryData: build.query({
    query: (arg) => {
      const params = new URLSearchParams();

      // Helper function to append nested objects
      const appendNestedParams = (key, value) => {
        if (value === undefined) {
          // Skip undefined values
          return;
        }
        if (typeof value === "object" && !Array.isArray(value)) {
          Object.keys(value).forEach((subKey) => {
            if (value[subKey] !== undefined) {
              params.append(`${key}.${subKey}`, value[subKey]);
            }
          });
        } else if (Array.isArray(value)) {
          value.forEach((val) => {
            if (val !== undefined) {
              params.append(key, val);
            }
          });
        } else {
          params.append(key, value);
        }
      };

      // Iterate over the keys in the arg object and append them to params
      Object.keys(arg).forEach((key) => {
        appendNestedParams(key, arg[key]);
      });

      return {
        url: `analysis/category`,
        params: params.toString()
      };
    },
    providesTags: ["analysiscategory"]
  }),

  postAnalysisSubCategoryData: build.query({
    query: (arg) => {
      const params = new URLSearchParams();

      // Helper function to append nested objects
      const appendNestedParams = (key, value) => {
        if (value === undefined) {
          // Skip undefined values
          return;
        }
        if (typeof value === "object" && !Array.isArray(value)) {
          Object.keys(value).forEach((subKey) => {
            if (value[subKey] !== undefined) {
              params.append(`${key}.${subKey}`, value[subKey]);
            }
          });
        } else if (Array.isArray(value)) {
          value.forEach((val) => {
            if (val !== undefined) {
              params.append(key, val);
            }
          });
        } else {
          params.append(key, value);
        }
      };

      // Iterate over the keys in the arg object and append them to params
      Object.keys(arg).forEach((key) => {
        appendNestedParams(key, arg[key]);
      });

      return {
        url: `analysis/subcategory`,
        params: params.toString()
      };
    },
    providesTags: ["analysissubcategory"]
  }),

  analysisEmissionByMonth: build.query({
    query: (arg) => {
      const params = new URLSearchParams();

      // Helper function to append nested objects
      const appendNestedParams = (key, value) => {
        if (value === undefined) {
          // Skip undefined values
          return;
        }
        if (typeof value === "object" && !Array.isArray(value)) {
          Object.keys(value).forEach((subKey) => {
            if (value[subKey] !== undefined) {
              params.append(`${key}.${subKey}`, value[subKey]);
            }
          });
        } else if (Array.isArray(value)) {
          value.forEach((val) => {
            if (val !== undefined) {
              params.append(key, val);
            }
          });
        } else {
          params.append(key, value);
        }
      };

      // Iterate over the keys in the arg object and append them to params
      Object.keys(arg).forEach((key) => {
        appendNestedParams(key, arg[key]);
      });

      return {
        url: `analysis/month`,
        params: params.toString()
      };
    },
    providesTags: ["analysismonth"]
  }),

  analysisEmissionByFacility: build.query({
    query: (arg) => {
      const params = new URLSearchParams();

      // Helper function to append nested objects
      const appendNestedParams = (key, value) => {
        if (value === undefined) {
          // Skip undefined values
          return;
        }
        if (typeof value === "object" && !Array.isArray(value)) {
          Object.keys(value).forEach((subKey) => {
            if (value[subKey] !== undefined) {
              params.append(`${key}.${subKey}`, value[subKey]);
            }
          });
        } else if (Array.isArray(value)) {
          value.forEach((val) => {
            if (val !== undefined) {
              params.append(key, val);
            }
          });
        } else {
          params.append(key, value);
        }
      };

      // Iterate over the keys in the arg object and append them to params
      Object.keys(arg).forEach((key) => {
        appendNestedParams(key, arg[key]);
      });

      return {
        url: `analysis/facility`,
        params: params.toString()
      };
    },
    providesTags: ["analysisfacility"]
  }),

  analysisEmissionTrendByMonthSource: build.query({
    query: (arg) => {
      const params = new URLSearchParams();

      // Helper function to append nested objects
      const appendNestedParams = (key, value) => {
        if (value === undefined) {
          // Skip undefined values
          return;
        }
        if (typeof value === "object" && !Array.isArray(value)) {
          Object.keys(value).forEach((subKey) => {
            if (value[subKey] !== undefined) {
              params.append(`${key}.${subKey}`, value[subKey]);
            }
          });
        } else if (Array.isArray(value)) {
          value.forEach((val) => {
            if (val !== undefined) {
              params.append(key, val);
            }
          });
        } else {
          params.append(key, value);
        }
      };

      // Iterate over the keys in the arg object and append them to params
      Object.keys(arg).forEach((key) => {
        appendNestedParams(key, arg[key]);
      });

      return {
        url: `analysis/trend/source`,
        params: params.toString()
      };
    },
    providesTags: ["analysistrendsource"]
  }),

  analysisYearSourceTrend: build.query({
    query: (arg) => {
      const params = new URLSearchParams();

      // Helper function to append nested objects
      const appendNestedParams = (key, value) => {
        if (value === undefined) {
          // Skip undefined values
          return;
        }
        if (typeof value === "object" && !Array.isArray(value)) {
          Object.keys(value).forEach((subKey) => {
            if (value[subKey] !== undefined) {
              params.append(`${key}.${subKey}`, value[subKey]);
            }
          });
        } else if (Array.isArray(value)) {
          value.forEach((val) => {
            if (val !== undefined) {
              params.append(key, val);
            }
          });
        } else {
          params.append(key, value);
        }
      };

      // Iterate over the keys in the arg object and append them to params
      Object.keys(arg).forEach((key) => {
        appendNestedParams(key, arg[key]);
      });

      return {
        url: `analysis/trend/source/year`,
        params: params.toString()
      };
    },
    providesTags: ["analysistrendyear"]
  }),

  analysisRenewableShare: build.query({
    query: (arg) => {
      const params = new URLSearchParams();

      // Helper function to append nested objects
      const appendNestedParams = (key, value) => {
        if (value === undefined) {
          // Skip undefined values
          return;
        }
        if (typeof value === "object" && !Array.isArray(value)) {
          Object.keys(value).forEach((subKey) => {
            if (value[subKey] !== undefined) {
              params.append(`${key}.${subKey}`, value[subKey]);
            }
          });
        } else if (Array.isArray(value)) {
          value.forEach((val) => {
            if (val !== undefined) {
              params.append(key, val);
            }
          });
        } else {
          params.append(key, value);
        }
      };

      // Iterate over the keys in the arg object and append them to params
      Object.keys(arg).forEach((key) => {
        appendNestedParams(key, arg[key]);
      });

      return {
        url: `analysis/renewable`,
        params: params.toString()
      };
    },
    providesTags: ["analysisrenewable"]
  }),

  analysisScopeEmission: build.query({
    query: (arg) => {
      const params = new URLSearchParams();

      // Helper function to append nested objects
      const appendNestedParams = (key, value) => {
        if (value === undefined) {
          // Skip undefined values
          return;
        }
        if (typeof value === "object" && !Array.isArray(value)) {
          Object.keys(value).forEach((subKey) => {
            if (value[subKey] !== undefined) {
              params.append(`${key}.${subKey}`, value[subKey]);
            }
          });
        } else if (Array.isArray(value)) {
          value.forEach((val) => {
            if (val !== undefined) {
              params.append(key, val);
            }
          });
        } else {
          params.append(key, value);
        }
      };

      // Iterate over the keys in the arg object and append them to params
      Object.keys(arg).forEach((key) => {
        appendNestedParams(key, arg[key]);
      });

      return {
        url: `analysis/scope`,
        params: params.toString()
      };
    },
    providesTags: ["analysisscope"]
  })
});
