import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ruleName: "",
  facility: null,
  period: null,
  ruleApplicability: "monthly",
  scope: null,
  category: null,
  subCategory: null,
  source: null,
  subSource: null,
  attribute: null,
  emissionParameter1: null,
  emissionParameter2: null,
  unit: null,
  minValue: null,
  minBuffer: "0",
  maxValue: null,
  maxBuffer: "0",
  status: "draft",
  blockOnAnomaly: false
};

const anomalySlice = createSlice({
  name: "anomaly",
  initialState,
  reducers: {
    setRuleName(state, action) {
      state.ruleName = action.payload;
    },
    setFacility(state, action) {
      state.facility = action.payload;
    },
    setPeriod(state, action) {
      state.period = action.payload;
    },
    setRuleApplicability(state, action) {
      state.ruleApplicability = action.payload;
    },
    setScope(state, action) {
      state.scope = action.payload;
    },
    setCategory(state, action) {
      state.category = action.payload;
    },
    setSubCategory(state, action) {
      state.subCategory = action.payload;
    },
    setSource(state, action) {
      state.source = action.payload;
    },
    setSubSource(state, action) {
      state.subSource = action.payload;
    },
    setAttribute(state, action) {
      state.attribute = action.payload;
    },
    setEmissionParameter1(state, action) {
      state.emissionParameter1 = action.payload;
    },
    setEmissionParameter2(state, action) {
      state.emissionParameter2 = action.payload;
    },
    setUnit(state, action) {
      state.unit = action.payload;
    },
    setMinValue(state, action) {
      state.minValue = action.payload;
    },
    setMinBuffer(state, action) {
      state.minBuffer = action.payload;
    },
    setMaxValue(state, action) {
      state.maxValue = action.payload;
    },
    setMaxBuffer(state, action) {
      state.maxBuffer = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    setBlockOnAnomaly(state, action) {
      state.blockOnAnomaly = action.payload;
    },
    resetAnomalyForm() {
      return initialState;
    }
  }
});

export const {
  setRuleName,
  setFacility,
  setPeriod,
  setRuleApplicability,
  setScope,
  setCategory,
  setSubCategory,
  setSource,
  setSubSource,
  setAttribute,
  setEmissionParameter1,
  setEmissionParameter2,
  setUnit,
  setMinValue,
  setMinBuffer,
  setMaxValue,
  setMaxBuffer,
  setStatus,
  setBlockOnAnomaly,
  resetAnomalyForm  
} = anomalySlice.actions;

export default anomalySlice.reducer; 