import React from "react";
import "./DefaultButton.scss";

const DefaultButton = ({ children, onClick, fontSize, disable, style, buttonSize }) => {
  return (
    <button
      className={`${disable ? "custom-button-disable" : "custom-button-default"} ${buttonSize === "sm" ? "small-default-button" : buttonSize === "md" ? "medium-default-button": "large-default-button"}`}
      onClick={onClick}
      disabled={disable}
      style={{ fontSize, ...style }}
    >
      {children}
    </button>
  );
};

export default DefaultButton;
